import React, { useEffect, useState } from "react";
import "./cart.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// icons
import { ReactComponent as TicketIcon } from "../../../assets/icons/icon-ticket.svg";

// images
import searchIllustration from "../../../assets/images/search-illustraion.svg";

// components
import CartItemCard from "../../../components/Cards/CartItemCard/CartItemCard";
import { useAppSelector } from "../../../redux/hooks";
import {
  applyCoupon,
  cartCheckout,
  getCartList,
} from "../../../redux/pages/courseSlice";
import ContentLoader from "../../../common/loader/loader";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from "@stripe/stripe-js";
import PaymentPage from "../PaymentPage/PaymentPage";

const Cart = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState<any>([]);
  const [cartPrice, setCartPrice] = useState<any>([]);
  const [coupon_code, setCouponCode] = useState("");
  const [invalidCoupon, setInvalidCoupon] = useState(false);

  const dispatch = useDispatch<any>();
  const cartInfo = useAppSelector((state) => state.course.cartList);
  const isLoading = useAppSelector((state) => state.course.isLoading);

  useEffect(() => {
    dispatch(getCartList());
  }, []);

  useEffect(() => {
    if (cartInfo?.cart_items) {
      setCartData(cartInfo?.cart_items);
    }
  }, [cartInfo?.cart_items]);

  useEffect(() => {
    if (cartInfo?.cart) {
      setCartPrice(cartInfo?.cart);
    }
  }, [cartInfo?.cart]);

  const searchHandler = () => {
    navigate("/course");
  };

  const handleCouponCodeChange = (e: any) => {
    setCouponCode(e.target.value);
    setInvalidCoupon(false);
  };

  const handleApplyCoupon = () => {
    if (coupon_code.trim() === "VALID_COUPON_CODE") {
      dispatch(applyCoupon({ coupon_code }));
    } else {
      setInvalidCoupon(true);
    }
  };

  const checkoutHandler = async () => {
    // const stripe = await loadStripe("pk_test_51OCzlHSFMxo5cmlFXUmjj6h00fML3NEBygdGaDCoqqev8RvIA2WyfA8JPmMcqRQcGPq5s10aQA1MSorTAw79yNGY00kWoo7wsP")
    dispatch(cartCheckout());
    navigate("/payment");
  };

  return (
    <div className="cart">
      <div className="cart-listing">
        <div className="container-lg">
          <div className="title-section">
            <h4 className="section-title">Cart</h4>
          </div>

          {cartData.length > 0 ? (
            <div className="row mb-5">
              <div className="col-12 col-md-8 order-2 order-md-1 ">
                <div className="cart-item-wrap">
                  <div className="row gy-3">
                    {cartData.map((course: any, key: any) => (
                      <div key={key} className="col-12 ">
                        <CartItemCard courseData={course} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 order-1 order-md-2 ">
                <div className="price-card">
                  <h4 className="title">Price Details</h4>
                  <ul>
                    <li>
                      <div className="price-det">
                        <p className="subtitle">SubTotal</p>
                        <p className="price">{cartPrice?.sub_total}</p>
                      </div>
                    </li>
                    <li>
                      <div className="price-det">
                        {/* <p className="subtitle">
                              Create a Chatbot using Python
                            </p>
                            <p className="price">₹ {cartData.price}</p> */}
                      </div>
                    </li>
                    <li>
                      <div className="price-det">
                        <p className="subtitle">Discount</p>
                        <p className="price discount-price">
                          -₹ {cartPrice?.descount}
                        </p>
                      </div>
                    </li>
                  </ul>
                  <div className="line"></div>
                  <div className="total-price">
                    <p className="subtitle">Total</p>
                    <p className="price">₹ {cartPrice?.total}</p>
                  </div>
                  <div className="mb-3">
                    {/* <StripeCheckout
        token={onToken}
        stripeKey={key}
      /> */}
                    <button
                      className="btn btn-lg btn-primary w-100 checkout-btn"
                      onClick={checkoutHandler}
                    >
                      Checkout
                    </button>
                  </div>
                  <div className="line"></div>
                  <div className="coupon-section">
                    <h5 className="section-title">Coupon</h5>
                    <p className="desc">
                      Apply coupon code to get additional discount
                    </p>
                    <div className="form-control-wrap mb-3">
                      <div className="icon-form-control">
                        <div className="start-icon">
                          <TicketIcon />
                        </div>

                        <input
                          className="form-control"
                          placeholder="Enter coupon code"
                          value={coupon_code}
                          onChange={handleCouponCodeChange}
                        />
                      </div>
                      {/* <p className="validation-text">Invalid coupon code!</p> */}
                      {invalidCoupon && (
                        <p className="validation-text">Invalid coupon code!</p>
                      )}
                    </div>
                    <button
                      className="btn btn-md btn-outline-primary w-100"
                      onClick={handleApplyCoupon}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-sm-10 col-lg-6 mx-auto">
                <div className="empty-cart mt-5 mb-5">
                  <div className="img-wrapper mb-3">
                    <img src={searchIllustration} alt="search illustration" />
                  </div>
                  <p className="desc mb-3">
                    Looks like your cart is empty, Don’t waste time, lets get
                    learn somthing new !
                  </p>
                  <button
                    className="btn btn-md btn-primary"
                    onClick={searchHandler}
                  >
                    Search Course
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
