import { RotatingLines } from  'react-loader-spinner'

const ContentLoader = () => {
   return (
      <div className="bg-light text-white d-flex align-items-center justify-content-center vh-100">
         <div className="text-center">
            <RotatingLines
               strokeColor="#d43d35"
               strokeWidth="5"
               animationDuration="0.95"
               width="50"
               visible={true}
            />
         </div>
      </div>
   )
};

export default ContentLoader;