
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useEffect, useState } from 'react';


const stripePromise = loadStripe('pk_test_51OCzlHSFMxo5cmlFXUmjj6h00fML3NEBygdGaDCoqqev8RvIA2WyfA8JPmMcqRQcGPq5s10aQA1MSorTAw79yNGY00kWoo7wsP')

  const options = {
    mode: 'payment' as const,
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };


  const PaymentPage = () => {

  return (
  <div className='container  m-5' >
    <Elements stripe={stripePromise} options={options} >
      <CheckoutForm  />
    </Elements>
   
  </div>
  ) 
};

export default PaymentPage;