import React, { useEffect, useState } from "react";
import "./course-streaming.scss";

// icons
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as ClipboardTextIcon } from "../../../assets/icons/icon-clipboard-text.svg";
import { ReactComponent as Book2Icon } from "../../../assets/icons/icon-book-2.svg";
import { ReactComponent as CupIcon } from "../../../assets/icons/icon-cup.svg";
import { ReactComponent as UserOctagonIcon } from "../../../assets/icons/icon-user-octagon.svg";
import { ReactComponent as HeartFilledIcon } from "../../../assets/icons/icon-heart-filled.svg";
import { ReactComponent as HeartOutlineIcon } from "../../../assets/icons/icon-heart-outline.svg";
import { ReactComponent as CheckCircleIcon } from "../../../assets/icons/icon-check-circle.svg";
import { ReactComponent as VideoOctagonIcon } from "../../../assets/icons/icon-video-octagon.svg";
import { ReactComponent as CheckCircleFilledIcon } from "../../../assets/icons/icon-tick-circle.svg";
import { ReactComponent as SendIcon } from "../../../assets/icons/icon-send.svg";

// components
import CourseCard from "../../../components/Cards/CourseCard/CourseCard";
import Chat from "../../../components/Chat/chat";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import Videocard from "../../../components/VideoCard/VideoCard";
import {
  chatStore, getChatList, myLearningDetails, questionList, setGetChatList, setmylearningDetails,
} from "../../../redux/pages/courseSlice";

const CourseStreaming = () => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { slug } = useParams();
  const id = location.state?.courseId;

  const [myLearningDetailsData, setMylearningDetailsData] = useState<any>([]);
  const [chapterdata, setChapterdata] = useState<any>([]);
  const [openChapter, setOpenChapter] = useState<any>();
  const [VdocipherID, setVdocipherID] = useState<any>();
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
  const [showTest, setShowTest] = useState<boolean>(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [questionData, setQuestionData] = useState<any>([]);
  const [test, setTest] = useState<any>("");
  const [subject_id, setSubject_id] = useState<any>("")
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
  const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);

  const mylearningDetailsInfo = useAppSelector((state) => state.course.myLearningDetails);
  const TestQuestionsInfo = useAppSelector((state) => state.course.TestQuestions);
  const chatInfo = useAppSelector((state) => state.course.chatList);

  const handleOptionSelect = (questionId: string, selectedOption: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionId]: selectedOption,
    }));
  };

  const nextHandler = () => {
    if (isLastQuestion) {
    } else {
      setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, questionData.length - 1));
    }
  };

  const prevHandler = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }

  useEffect(() => {
    setIsLastQuestion(currentQuestionIndex === questionData.length - 1);
  }, [currentQuestionIndex, questionData]);

  useEffect(() => {
    setQuestionData(TestQuestionsInfo?.questions || []);
    setTest(TestQuestionsInfo);
  }, [TestQuestionsInfo]);

  useEffect(() => {
    if (id && slug) {
      dispatch(myLearningDetails(id, slug));
    }
    return () => {
      dispatch(setmylearningDetails([]));
    };
  }, [id, slug, dispatch]);

  useEffect(() => {
    if (mylearningDetailsInfo) {
      setMylearningDetailsData(mylearningDetailsInfo);
      setSubject_id(mylearningDetailsInfo?.id);
      setChapterdata(mylearningDetailsInfo?.chapters);

      if (
        mylearningDetailsInfo?.chapters &&
        mylearningDetailsInfo.chapters.length > 0
      ) {
        mylearningDetailsInfo.chapters.forEach((chapter: any) => {
          const firstTutorialWithVideo = chapter?.tutorial.find(
            (tutorial: { vdocipher_id?: any }) => tutorial.vdocipher_id
          );

          if (firstTutorialWithVideo && autoPlay) {
            videoHandler(firstTutorialWithVideo.vdocipher_id);
            setAutoPlay(false);
          }
        });
      }
    }
  }, [mylearningDetailsInfo, autoPlay]);


  const videoHandler = (id: any) => {
    setVdocipherID(id);
    setShowVideoPlayer(true);
    setShowTest(false);
  };

  const testHandler = (id: any) => {
    setShowTest(true);
    setShowVideoPlayer(false);
    dispatch(questionList(id));
    setShowTest(!showTest);
  };

  return (
    <>
      <div className="course-streaming-page">
        <div className="course-streaming">
          <div className="container-lg">
            <div className="row">
              {/* video player */}
              <div className="col-12 col-xl-7 col-xxl-8">
                <div className={"video-player-wrapper"}>
                  <h4 className="course-title">
                    {" "}
                    {myLearningDetailsData?.chapter?.tutorial?.name}{" "}
                  </h4>
                  {showVideoPlayer ? (
                    <div className="video-player ">
                      {VdocipherID && !showTest ? (
                        <Videocard vdocipher_id={VdocipherID} />
                      ) : (
                        <p>Select a video to play.</p>
                      )}
                    </div>
                  ) : null}
                  {/* video player  */}

                  {showTest && questionData && questionData.length > 0 ? (
                    <div className="test-area">
                      <h3 className="test-title">{test?.name}</h3>
                      <div className="test-wrapper">
                        <div
                          key={questionData[currentQuestionIndex]?.id}
                          className="mb-4"
                        >
                          <h4 className="question mb-4">
                            {currentQuestionIndex + 1}.{" "}
                            {questionData[currentQuestionIndex]?.question}
                          </h4>

                          {questionData[currentQuestionIndex]?.options.map(
                            (option: any) => (
                              <div key={option.id} className="row g-sm-4">
                                <div className="col-12 col-sm-6">
                                  <div className="form-radio-btn">
                                    <input
                                      type="radio"
                                      name="radioDefault"
                                      id={`radio${option.id}`}
                                      onChange={() => handleOptionSelect(questionData[currentQuestionIndex]?.id, option?.option)}
                                      checked={selectedOptions[questionData[currentQuestionIndex]?.id] === option?.option}
                                    />
                                    <label htmlFor={`radio${option.id}`}>
                                      {option?.option}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        {/* <div className="results-sec">
                          <div className="result-title">congratulation</div> */}
                        {/* use class names "green" for green color, and "red" for red color */}
                        {/* <span className="score red">8</span>
                          <p className="results">
                            You scored <span className="score green">8</span>{" "}
                            out of <span className="score">10</span>
                          </p>
                        </div> */}

                        <div className="mt-5 d-flex align-items-center justify-content-between">

                          <button onClick={prevHandler} className="btn btn-md btn-outline-primary" disabled={currentQuestionIndex === 0}>
                            Prev
                          </button>
                          <button onClick={nextHandler} className={`btn btn-md btn-outline-primary ${isLastQuestion ? "submit-button" : ""}`}>
                            {isLastQuestion ? "Submit" : "Next"}
                          </button>

                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* tab section */}
                <div className="tab-section">
                  <div className="nav-wrap">
                    <ul
                      className="nav nav-pills mb-3 mb-md-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-about-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-about"
                          type="button"
                          role="tab"
                          aria-controls="pills-about"
                          aria-selected="true"
                        >
                          About
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-test-score-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-test-score"
                          type="button"
                          role="tab"
                          aria-controls="pills-test-score"
                          aria-selected="false"
                        >
                          Test Score
                        </button>
                      </li> */}
                    </ul>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-about"
                      role="tabpanel"
                      aria-labelledby="pills-about-tab"
                    >
                      <div className="mw-962">
                        <div className="tab-title-wrap">
                          {/* <h4 className="tab-title">About</h4> */}
                          <p className="desc">
                            {myLearningDetailsData.description}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-test-score"
                      role="tabpanel"
                      aria-labelledby="pills-test-score-tab"
                    >
                      <div className="mw-962">
                        <div className="row">
                          <div className="col-12">
                            <div className="test-score-card">
                              <div>
                                <h5 className="title">Chapter 1 Test 1</h5>
                              </div>
                              <p className="score">
                                {/* <span className="green">8</span> */}
                                <span className="red">3</span>
                                /20
                              </p>
                              <div>
                                <button className="btn btn-sm btn-outline-primary">
                                  Take Retest
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="test-score-card">
                              <div>
                                <h5 className="title">Chapter 1 Test 1</h5>
                              </div>
                              <p className="score">
                                {/* <span className="green">8</span> */}
                                <span className="red">3</span>
                                /20
                              </p>
                              <div>
                                <button className="btn btn-sm btn-outline-primary">
                                  Take Retest
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="test-score-card">
                              <div>
                                <h5 className="title">Chapter 1 Test 1</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* course content section */}
              <div className="col-12 col-xl-5 col-xxl-4">
                {/* course content */}
                <div className="sub-section mb-5 mb-xl-3">
                  <h4 className="sub-section-title mb-3 d-none d-xl-block">
                    {myLearningDetailsData.name}
                  </h4>
                  {/* NOTE: for devs: content added only for first accordion, 2 and 3 are just placeholders please don't change the classnames from its position  , also give unique id names accordingly -- remove this comment after integration */}
                  <div className="course-content-section">
                    <div className="course-sec-scroll-area">
                      <div
                        className="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        {chapterdata?.map((chapter: any) => (
                          <div
                            className="accordion-item chapter-accordion"
                            key={chapter.id}
                          >
                            <h2
                              className="accordion-header"
                              id={`flush-heading${chapter.id}`}
                            >
                              <button
                                className={`accordion-button ${openChapter === chapter.id ? "" : "collapsed"
                                  }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#flush-collapse${chapter.id}`}
                                aria-expanded={
                                  openChapter === chapter.id ? "true" : "false"
                                }
                                aria-controls={`flush-collapse${chapter.id}`}
                              >
                                {chapter?.name}
                              </button>
                            </h2>
                            <div
                              id={`flush-collapse${chapter.id}`}
                              className={`accordion-collapse collapse ${openChapter === chapter.id ? "show" : ""
                                }`}
                              aria-labelledby={`flush-heading${chapter.id}`}
                              data-bs-parent="#accordionFlushExample"
                            >
                              {chapter?.tutorial.map((tutorial: any) => (
                                <div
                                  className="accordion-body"
                                  key={tutorial.id}
                                >
                                  {/* inner cards - completed */}
                                  <button
                                    onClick={() => {
                                      videoHandler(tutorial?.vdocipher_id);
                                    }}
                                    className={`inner-card completed${tutorial.id}`}
                                    type="button"
                                  >
                                    <div className="d-flex align-items-center">
                                      <span className="start-icon me-2">
                                        <VideoOctagonIcon />
                                      </span>
                                      <h6 className="inner-heading">
                                        {tutorial.name}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <p className="text me-2">23:45</p>
                                      <span className="end-icon">
                                        <CheckCircleFilledIcon />
                                      </span>
                                    </div>
                                  </button>

                                  {chapter?.test && (
                                    <button
                                      className="inner-card"
                                      type="button"
                                      onClick={() =>
                                        testHandler(chapter?.test?.id)
                                      }
                                    >
                                      <div
                                        className="d-flex align-items-center"
                                        key={chapter?.test?.id}
                                      >
                                        <span className="start-icon me-2">
                                          <ClipboardTextIcon />
                                        </span>
                                        <h6 className="inner-heading">
                                          {" "}
                                          {chapter?.test?.name}{" "}
                                        </h6>
                                      </div>
                                      <div
                                        className="d-flex align-items-center"
                                        key={chapter?.id}
                                      >
                                        <p className="text me-2"> 5 Que </p>
                                        <span className="end-icon">
                                          <div className="circle"></div>
                                        </span>
                                      </div>
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                {/* doubts section */}
                <div className="doubts-wrapper mb-5 d-none d-xl-block">
                  <div className="title-sec">
                    <h5 className="title">Doubts</h5>
                  </div>
                  <div className="chat-section">
                    <div className="scroll-area">
                      <Chat subject_id={subject_id} />
                    </div>
                    {/* <div className="input-wrapper"> */}

                    {/* <div className="form-group">
                      <input type="text"  className="form-control"  placeholder="Type a message"
                       value={InputMessage}  onChange={handleChatChange} 
                       />
                      <button className="send-btn" onClick={messageSendHandler}>
                        <SendIcon />
                      </button>
                    </div> */}

                    {/* </div> */}
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseStreaming;
