import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getVdocipherOtp, setVdocipherOtp } from '../../redux/pages/courseSlice';
import { useAppSelector } from '../../redux/hooks';

interface IDVideoCard {
  vdocipher_id: any;
};

const Videocard: React.FC<IDVideoCard> = (props) => {

  const {
    vdocipher_id = {}
  } = props;

  const dispatch = useDispatch<any>()
  const vdocipherInfo = useAppSelector((state) => state.course.vdocipherOtp);

  const [otp, setOtp] = useState<any>();
  const [playbackID, setPlaybackID] = useState<any>();

  useEffect(() => {
    if (vdocipher_id) {
      dispatch(getVdocipherOtp(vdocipher_id))
    }
    return () => {
      dispatch(setVdocipherOtp({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (vdocipherInfo) {
      setOtp(vdocipherInfo?.data?.otp);
      setPlaybackID(vdocipherInfo?.data?.playbackInfo);
    }
  }, [vdocipherInfo]);

  return (
    <div>
      <iframe
        src={`https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackID}`}
        style={{ border: "0", width: "720px", height: "405px" }}
        allow="encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default Videocard